import { css } from '@emotion/react';
import Star from 'practice_room/components/shared/stat-with-stars/star';
import { useEffect, useRef } from 'react';
import {
  halfSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import { random } from 'underscore';
import { tooltipZIndex } from './constants';

type StarsConfettiProps = {
  children: React.ReactElement;
  showLeft?: boolean;
  showRight?: boolean;
};

const containerStyles = css`
  width: 100%;
  position: relative;
`;

const styles = css`
  pointer-events: none;
  position: absolute;
  top: -${halfSpacing}px;
  width: ${standardSpacing}%;
  height: 100%;
  z-index: ${tooltipZIndex + 1};

  &.show-left {
    left: -${halfSpacing}px;
  }

  &.show-right {
    right: -${halfSpacing}px;
  }

  /* Styles associated with each star */
  .star {
    position: absolute;
    top: 0px;
    opacity: 0;
    font-size: 0px; /* To position svg correctly */

    svg {
      transform: translateZ(0px);
    }

    .close-star {
      transform: rotate(45deg);
    }
  }
`;

const STAR_MAX_SIZE = 10;

// Insert stars to the element given
const insertStars = (element) => {
  for (let i = 0; i < random(35, 45); i += 1) {
    // eslint-disable-next-line no-new
    new Star(element, STAR_MAX_SIZE);
  }
};

const StarsConfetti = ({
  children,
  showLeft = false,
  showRight = false,
}: StarsConfettiProps) => {
  const leftElement = useRef(null);
  const rightElement = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (
        showLeft
        && leftElement.current
        && leftElement.current.offsetParent !== null
      ) {
        insertStars(leftElement.current);
      }
      if (
        showRight
        && rightElement.current
        && rightElement.current.offsetParent !== null
      ) {
        insertStars(rightElement.current);
      }
    }, 100);
  }, [showLeft, showRight]);

  const commonClassName = 'star-item d-flex align-items-center text-success';

  return (
    <div css={containerStyles}>
      {showLeft && (
        <div
          css={styles}
          ref={leftElement}
          className={`${commonClassName} show-left`}
        />
      )}
      {children}
      {showRight && (
        <div
          css={styles}
          ref={rightElement}
          className={`${commonClassName} show-right`}
        />
      )}
    </div>
  );
};

export default StarsConfetti;
