import { css } from '@emotion/react';
import CustomRadioGroup from 'components/custom-radio-group';
import { PollOptionNormalized } from 'redux/schemas/models/poll';
import { gray5, primary } from 'styles/global_defaults/colors';
import { openSans } from 'styles/global_defaults/fonts';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';

interface PollSelectOptionProps {
  options: PollOptionNormalized[];
  onSelectOption(value: number): void;
  selectedOption: undefined | number;
  disabled: boolean;
}

/* @ngInject */
export default function PollSelectOption(props: PollSelectOptionProps) {
  // radio buttons uses string values but we have number Ids, so here we convert them
  const activeValue = typeof props.selectedOption === 'number' ? String(props.selectedOption) : '';
  return (
    <div
      css={css`
        margin-top: ${standardSpacing}px;
      `}
    >
      <CustomRadioGroup
        radios={props.options.map((option, index) => ({
          value: String(option.id),
          option, // we pass the entire option object to use it latter in renderRadioButton
          disabled: props.disabled,
        }))}
        name='poll'
        onChange={(val) => {
          // convert them back to integer
          props.onSelectOption(parseInt(val, 10));
        }}
        activeValue={activeValue}
        renderRadioButton={({ option, index }) => (
          <div
            css={optionRadioButtonStyles}
          >
            {option.optionContent}
          </div>
        )}
      />
    </div>
  );
}

const optionRadioButtonStyles = css`
  font-size: 16px;
  line-height: ${largeSpacing}px;
  font-weight: normal;
  font-family: ${openSans};
  border-radius: ${quarterSpacing}px;
  border: 1px solid ${gray5};
  padding: ${threeQuartersSpacing}px ${standardSpacing}px;
  margin-bottom: ${halfSpacing}px;

  input:checked + & {
    border-color: ${primary};
  }
  input:focus + & {
    border: 2px solid ${primary};
  }

  input:not([disabled]) + &:hover,
  input:checked + & {
    color: ${primary};
  }
`;
